import { useState } from "react";
import { ProfessionModel } from "../../domains/profession/profession.types";
import { getItemTranslation } from "../../domains/app/utils/getItemTranslation";
import { useAppLang } from "../../domains/app/hooks/useAppLang";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderLogo, NavLang } from "../../components/app/headers/layout";
import StepBars from "../../components/app/StepBars";
import {
  OnboardingHeading,
  OnboardingHero,
  OnboardingSelectionRow,
  OnboardingSelectionWrapper,
} from "../../components/onboarding/layout";
import i18n from "../../config/i18n";

export const MOST_POPULAR = [
  "physician",
  "medical-resident",
  "medical-student",
  "professional-in-healthcare-industry",
  "nurse-practitioner",
  "registered-nurse",
  "physican-assistant",
  "pharmacist",
];

export default function OnboardingProfession({ professions, onSubmit }: {
  professions: ProfessionModel[] | [];
  onSubmit: Function;
}) {
  const { t } = useAppLang();
  const [selectedProfessionUid, setSelectedProfessionUid] = useState("");

  function handleSelectProfession(profession: ProfessionModel) {
    setSelectedProfessionUid(profession.uid);
    setTimeout(() => {
      onSubmit(profession);
    }, 300);
  }

  return (
    <>
      <HeaderBoxAutoSafe>
        <HeaderLinks />
        <HeaderLogo />
        <HeaderLinks><NavLang /></HeaderLinks>
      </HeaderBoxAutoSafe>

      <OnboardingHero>
        <StepBars maxSteps={4} currentStep={2} />
        <OnboardingHeading>
          <h1>{t("signup:onboarding.profession.title")}</h1>
          <span className="subtitle">{t("signup:onboarding.profession.subtitle")}</span>
        </OnboardingHeading>
      </OnboardingHero>

      <OnboardingSelectionWrapper className="popular">
        <span className="help">{t("signup:onboarding.profession.prompt")}:</span>
        <h2>{t("signup:onboarding.profession.mostPopular")}</h2>
        {professions
          .filter((profession: ProfessionModel) => MOST_POPULAR.includes(profession.uid))
          .sort((a: ProfessionModel, b: ProfessionModel) => {
            return MOST_POPULAR.indexOf(a.uid) - MOST_POPULAR.indexOf(b.uid);
          })
          .map((profession: ProfessionModel, index) => {
            return (
              <OnboardingSelectionRow key={index}>
                <span>{getItemTranslation(profession, i18n.resolvedLanguage)}</span>
                <button
                  className={
                    selectedProfessionUid === profession?.uid ? "selected" : ""
                  }
                  onClick={() => handleSelectProfession(profession)}
                >
                  {t("common:action.select")}
                </button>
              </OnboardingSelectionRow>
            );
          })
        }
      </OnboardingSelectionWrapper>

      <OnboardingSelectionWrapper>
        <h2>{t("signup:onboarding.profession.otherProfessions")}</h2>
        {professions
          .filter((profession: ProfessionModel) => !MOST_POPULAR.includes(profession.uid))
          .sort((a: ProfessionModel, b: ProfessionModel) => {
            return getItemTranslation(a, i18n.resolvedLanguage).localeCompare(
              getItemTranslation(b, i18n.resolvedLanguage)
            );
          })
          .map((profession: ProfessionModel, index) => {
            return (
              <OnboardingSelectionRow key={index}>
                <span>{getItemTranslation(profession, i18n.resolvedLanguage)}</span>
                <button
                  className={
                    selectedProfessionUid === profession?.uid ? "selected" : ""
                  }
                  onClick={() => handleSelectProfession(profession)}
                >
                  {t("common:action.select")}
                </button>
              </OnboardingSelectionRow>
            );
          })
        }
      </OnboardingSelectionWrapper>
    </>
  );
}
