import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SupportedLanguagesEnum } from "../../interfaces";
import { DEFAULT_LANGUAGE } from "../../config/i18n";

interface ReducerState {
  publicAppLang: SupportedLanguagesEnum | null;
  currentRoomLang: SupportedLanguagesEnum | null;
}

const initialState: ReducerState = {
  publicAppLang: null,
  currentRoomLang: null,
};

export const slice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    setPublicAppLang: (state, action: PayloadAction<SupportedLanguagesEnum>) => {
      state.publicAppLang = action.payload;
    },
    setCurrentRoomLang: (state, action: PayloadAction<SupportedLanguagesEnum>) => {
      state.currentRoomLang = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPublicAppLang,
  setCurrentRoomLang,
} = slice.actions;

export default slice.reducer;
