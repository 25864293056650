import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
// API (RTK Query)
import { articlesApi } from "../domains/article/article.api";
import { forYouApi } from "../domains/forYou/forYou.api";
import { gptApi } from "../domains/gpt/gpt.api";
import { journalsApi } from "../domains/journal/journals.api";
import { nectarApi } from "../domains/nectar/nectar.api";
import { playlistsApi } from "../domains/playlist/playlists.api";
import { searchApi } from "../domains/search/search.api";
import { specialtiesApi } from "./specialties/specialties.service";
import { userApi } from "./user/user.service";
import { videosApi } from "../domains/video/videos.api";
// Reducers
import langReducer from "../domains/lang/lang.reducer";
import articlesReducer from "../domains/article/article.reducer";
import forYouReducer from "../domains/forYou/forYou.reducer";
import journalsReducer from "../domains/journal/journals.reducer";
import nectarsReducer from "../domains/nectar/nectar.reducer";
import playlistsReducer from "../domains/playlist/playlists.reducer";
import searchReducer from "../domains/search/search.reducer";
import specialtiesReducer from "./specialties/specialties.reducer";
import userReducer from "./user/user.reducer";
import videosReducer from "../domains/video/videos.reducer";

export const store: any = configureStore({
  reducer: {
    lang: langReducer,
    articles: articlesReducer,
    search: searchReducer,
    user: userReducer,
    journals: journalsReducer,
    playlists: playlistsReducer,
    videos: videosReducer,
    nectars: nectarsReducer,
    forYou: forYouReducer,
    specialties: specialtiesReducer,
    [articlesApi.reducerPath]: articlesApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [journalsApi.reducerPath]: journalsApi.reducer,
    [playlistsApi.reducerPath]: playlistsApi.reducer,
    [videosApi.reducerPath]: videosApi.reducer,
    [nectarApi.reducerPath]: nectarApi.reducer,
    [gptApi.reducerPath]: gptApi.reducer,
    [forYouApi.reducerPath]: forYouApi.reducer,
    [specialtiesApi.reducerPath]: specialtiesApi.reducer,
  },
  // Adding the api middlewares enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      articlesApi.middleware,
      searchApi.middleware,
      userApi.middleware,
      journalsApi.middleware,
      playlistsApi.middleware,
      videosApi.middleware,
      nectarApi.middleware,
      gptApi.middleware,
      forYouApi.middleware,
      specialtiesApi.middleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
