import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { useLazyGetCurrentUserQuery } from "../../domains/user/endpoints/getCurrentUser2";
import { t } from "i18next";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { gaEventViewItemListVideo } from "../../tools/analytics/videoAnalytics";
import { gaEventViewItemListArticle } from "../../tools/analytics/articleAnalytics";
import { gaEventViewItemListNectar } from "../../tools/analytics/nectarAnalytics";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useLazyGetForYouContentQuery } from "../../domains/forYou/endpoints/getForYouContent";
import { useAppLang } from "../../domains/app/hooks/useAppLang";
import { displayToast } from "../../components/app/AppToast";
import BottomNavbar from "../../components/app/BottomNavbar";
import styled from "styled-components";
import { clearRecommendedContent, memorizeRecommendedContent } from "../../domains/forYou/forYou.reducer";
import DownloadAppModal from "../../components/home/modals/DownloadAppModal";
import ForYouMainSlider from "../../components/forYou/ForYouMainSlider";

export default function ForYouPage() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const recommendedContent = useAppSelector((state) => state.forYou.cache.recommendedContent);
  const { appLang } = useAppLang();
  const { currentUser } = useCurrentUser();
  const [isLoadingContent, setLoadingContent] = useState(!recommendedContent.length);
  const [isSliderLocked, setSliderLocked] = useState(true);
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const [getForYouContent, {
    isFetching: isFetchingContent,
  }] = useLazyGetForYouContentQuery();

  useEffect(() => {
    (async function () {
      try {
        // NOTE: Landing page, split auth & onboarding checks
        const { isAuthentified } = await preflightUser({ history, authOnly: true });
        if (!isAuthentified) return;

        if (!currentUser) await getCurrentUser();

        scrollTo(0, 0);

        const { isRedirected } = await preflightUser({
          history,
          onboardingMessage: t("error:notOnboarded.foryou"),
        });
        if (isRedirected) return;

        if (!recommendedContent.length) {
          loadRecommendedContent(true);
        }
      } catch (error) {
        console.error("Couldn't mount For You page.", error);
        displayToast(t("error:default"));
        history.replace("/discovery");
      }
    })();

    return () => {
      // NOTE: Clear cache to force refetch when back from other sections than For You
      if (!history.location.pathname.startsWith("/foryou")) {
        dispatch(clearRecommendedContent());
        localStorage.removeItem("foryou_carousel_index");
      }
    }
  }, []);

  function loadRecommendedContent(resetList = false) {
    try {
      getForYouContent({
        limit: 10,
        offset: resetList ? 0 : recommendedContent.length,
        language: appLang,
      })
        .unwrap()
        .then((docs) => {
          const newList = resetList ? docs : [...recommendedContent, ...docs];
          dispatch(memorizeRecommendedContent(newList));
          handleGTM(docs);
          setLoadingContent(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error("Couldn't load recommended content.");
      displayToast(t("error:default"));
    }
  }

  function handleGTM(docs: any[]) {
    const articles = [];
    const videos = [];
    const nectars = [];

    for (const item of docs) {
      if ("apiVideo" in item) {
        videos.push(item);
      } else if ("answer" in item) {
        nectars.push(item);
      } else {
        articles.push(item);
      }
    }

    if (articles.length) {
      gaEventViewItemListArticle({
        articles,
        listName: AnalyticsItemList.FORYOU,
      });
    }

    if (nectars.length) {
      gaEventViewItemListNectar({
        nectars,
        listName: AnalyticsItemList.FORYOU,
      });
    }

    if (videos.length) {
      gaEventViewItemListVideo({
        videos,
        listName: AnalyticsItemList.FORYOU,
      });
    }
  }

  function handleSlideChange(newIndex: number) {
    if (newIndex >= recommendedContent.length - 3) {
      if (recommendedContent.length < 50 && !isFetchingContent) {
        loadRecommendedContent();
      }
    }
  }

  return (
    <>
      <Wrapper>
        <ForYouMainSlider
          contentList={recommendedContent}
          isLoading={isLoadingContent}
          // TODO: improve this part
          isLocked={isSliderLocked || isLoadingContent}
          onSlideChange={handleSlideChange}
        />

        <BottomNavbar />
      </Wrapper>

      <DownloadAppModal onClose={() => setSliderLocked(false)} />
    </>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  min-height: 100dvh;
  max-height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  background: linear-gradient(180deg, #FFC408 0%, rgba(255, 255, 255, 0.00) 100%), #F7F6F2;
`;
