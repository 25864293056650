import React, { useEffect, useState, SyntheticEvent } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector } from "../../redux";
import { ArticleModel } from "../../domains/article/article.types";
import { PlaylistModel } from "../../domains/playlist/playlist.types";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { useLazyGetArticlesListQuery } from "../../domains/article/endpoints/getArticlesList";
import { useRoomPreflightTemp } from "../../domains/room/hooks/useRoomPreflightTemp";
import {
  getStoredRoomLang,
  useAppLang,
} from "../../domains/app/hooks/useAppLang";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import disableScroll from "disable-scroll";
import Loader from "../../components/Loader";
import FadeIn from "react-fade-in/lib/FadeIn";
import InfiniteScroll from "react-infinite-scroll-component";
import SkeletonContentCard from "../../components/profile/SkeletonContentCard";
import LanguagePanel from "../../components/app/LanguagePanel";
import SafeAreaTopWrapper from "../../components/app/SafeAreaTopWrapper";
import {
  HeaderBoxSimple,
  HeaderLinks,
  HeaderLogoTitleTagline,
  NavHome,
  NavLang,
  NavOptions,
} from "../../components/app/headers/layout";
import {
  RoomListHeading,
  RoomListWrapper,
  RoomPageTab,
  RoomPageWrapper,
  RoomTabSwitcher,
} from "../../components/room/layout";
import HomeNectarScroller from "../../components/home/home-blocks/HomeNectarScroller";
import ArticleCard from "../../components/cards/ArticleCard";
import { useLazyGetPublicPlaylistsQuery } from "../../domains/playlist/endpoints/getPublicPlaylists";
import MiniPlaylistScroller from "../../components/app/MiniPlaylistScroller";
import styled from "styled-components";
import RoomOptionsPanel from "../../components/room/RoomOptionsPanel";
import DownloadAppModal from "../../components/home/modals/DownloadAppModal";
import CustomIcon from "../../components/CustomIcon";
import { ReactComponent as FeedIcon } from "../../assets/icons/feed-white.svg";
import TakedaDisclaimerModal from "../../components/room/TakedaDisclaimerModal";
import { isNativeIOS } from "../../tools/ios";

// TODO: Header: NavOptions: to do what?

export default function RoomDiscoveryPage() {
  const history = useHistory();
  const { roomId } = useParams<{ roomId: string }>();
  const { appLang, t } = useAppLang();
  const currentRoomId = useAppSelector((state) => state.user.currentRoomId);
  const [isLangSelected, setLangSelected] = useState(
    getStoredRoomLang(roomId) !== null
  );
  const [isOptionsPanelVisible, setOptionsPanelVisible] = useState(false);
  const [isDisclaimerVisible, setDisclaimerVisible] = useState(false);
  const [playlists, setPlaylists] = useState<PlaylistModel[]>([]);
  const [articles, setArticles] = useState<ArticleModel[]>([]);
  // NOTE: Using state here instead of RTK Query state to prevent glitch before first fetch.
  const [total, setTotal] = useState(0);
  const [getPublicPlaylists, { isLoading: isLoadingPlaylists }] =
    useLazyGetPublicPlaylistsQuery();
  const [
    getArticlesList,
    {
      isUninitialized: isUninitializedArticles,
      isFetching: isFetchingArticles,
    },
  ] = useLazyGetArticlesListQuery();
  const { isLoadingRoom, roomData, preflightUserRoom } =
    useRoomPreflightTemp(roomId);
  const [isTakedaRoom, setIsTakedaRoom] = useState(false);

  // TODO: cache articles

  useEffect(() => {
    (async function () {
      try {
        const { isRedirected } = await preflightUser({
          history,
          onboardingMessage: t("error:notOnboarded.default"),
        });
        if (isRedirected) return;

        disableScroll.off();

        // NOTE: Temporary check until we get a true access guard on rooms.
        await preflightUserRoom();
      } catch (error) {
        console.error("Couldn't load room.", error);
        history.push("/discovery");
      }
    })();
  }, []);

  useEffect(() => {
    if (currentRoomId) {
      scrollTo(0, 0);
      loadPlaylists();
      loadArticles(true);
    }
  }, [currentRoomId, appLang]);

  useEffect(() => {
    if (
      roomData &&
      roomData.organisation.uid.toLowerCase().includes("takeda")
    ) {
      setIsTakedaRoom(true);
      const hasAcceptedDisclaimer =
        localStorage.getItem("acceptedRulesTakedaRoom") === "true";

      if (!hasAcceptedDisclaimer) {
        setDisclaimerVisible(true);
      }
    } else {
      setIsTakedaRoom(false);
    }
  }, [roomData]);

  async function loadPlaylists() {
    try {
      getPublicPlaylists({
        limit: 20,
        offset: 0,
        language: appLang,
      })
        .unwrap()
        .then(({ docs }) => {
          setPlaylists(docs);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error("Couldn't load playlists.", error);
    }
  }

  async function loadArticles(resetList = false) {
    try {
      getArticlesList({
        limit: 20,
        offset: resetList ? 0 : articles.length,
        language: appLang,
      })
        .unwrap()
        .then(({ docs, total }) => {
          const newList = resetList ? docs : [...articles, ...docs];
          setArticles(newList);
          setTotal(total);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error("Couldn't load articles.", error);
    }
  }

  function handleDisclaimerAccept() {
    setDisclaimerVisible(false);
    localStorage.setItem("acceptedRulesTakedaRoom", "true");
  }

  if (isLoadingRoom) return <Loader />;

  if (!isLangSelected) {
    return (
      <LanguagePanel
        isVisible={!isLangSelected}
        onClose={() => {
          setLangSelected(true);
        }}
        displayConfirmButton={true}
      />
    );
  }

  return (
    <>
      <Wrapper style={{ backgroundColor: roomData?.primaryColor ?? "" }}>
        <SafeAreaTopWrapper>
          <HeaderBoxSimple>
            <HeaderLinks>
              <NavHome />
            </HeaderLinks>
            <HeaderLogoTitleTagline
              logo={roomData?.organisation?.logo?.url || ""}
              title={roomData?.name || ""}
              tagline={roomData?.organisation.name || ""}
            />
            <HeaderLinks>
              <NavLang />
              <NavOptions onClick={() => setOptionsPanelVisible(true)} />
            </HeaderLinks>
          </HeaderBoxSimple>

          <RoomTabSwitcher activeTab={RoomPageTab.DISCOVERY} />
        </SafeAreaTopWrapper>

        {/* TODO: Make new component for rooms. */}
        {/* <HomeNectarScroller /> */}

        {/* TODO: Localize + Link see all */}
        <MiniPlaylistScroller
          title={t("room:discovery.playlists")}
          linkTo="#"
          playlists={playlists}
          isLoading={isLoadingPlaylists}
        />

        <RoomListHeading>
          <FeedIcon />
          {t("room:discovery.feed")}
        </RoomListHeading>

        {isUninitializedArticles || isFetchingArticles ? (
          <FadeIn key="loading">
            <RoomListWrapper>
              {[...Array(10)].map((_, index) => (
                <SkeletonContentCard key={index} />
              ))}
            </RoomListWrapper>
          </FadeIn>
        ) : (
          <FadeIn key="content">
            <InfiniteScroll
              dataLength={articles.length}
              next={() => loadArticles()}
              hasMore={articles.length < total}
              pullDownToRefresh={false}
              loader={null}
            >
              <RoomListWrapper>
                {!articles.length && (
                  <span className="no-results">
                    {t("room:discovery.noResults")}
                  </span>
                )}
                {articles.map((article) => {
                  // TODO: Analytics item list for rooms.
                  return (
                    <ArticleCard
                      key={article._id}
                      article={article}
                      analyticsListName={
                        AnalyticsItemList.DISCOVERY_MAIN_CAROUSEL
                      }
                    />
                  );
                })}
              </RoomListWrapper>
            </InfiniteScroll>
          </FadeIn>
        )}
        {isTakedaRoom && (
          <div
            className={`fixed-footer ${isNativeIOS ? "safe-area-space" : ""}`}
          >
            {t("room:discovery.takedaRoomFooter")}
          </div>
        )}
      </Wrapper>

      <RoomOptionsPanel
        roomId={roomId}
        isVisible={isOptionsPanelVisible}
        onClose={() => setOptionsPanelVisible(false)}
      />

      <TakedaDisclaimerModal
        visible={isDisclaimerVisible}
        onPress={handleDisclaimerAccept}
        roomLang="en"
      />

      <DownloadAppModal />
    </>
  );
}

const Wrapper = styled(RoomPageWrapper)`
  .mini-playlist-scroller-container {
    background-color: white;
  }

  .fixed-footer {
    color: #ed1c24;
    font-family: Inter;
    font-size: 11px;
    font-weight: 600;
    line-height: 12.1px;
    text-align: center;

    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 12px 0;
    background: #f9f9f9;
    z-index: 999;

    &.safe-area-space {
      padding-bottom: 36px;
    }
  }
`;
