import { useEffect, useState } from "react";
import { useAppLang } from "../../domains/app/hooks/useAppLang";
import { SupportedLanguagesEnum } from "../../interfaces";
import { CustomButton } from "../../components/global";
import { PolicyTextStyle } from "../../components/PolicyTextStyle";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavBack } from "../../components/app/headers/layout";
import LogoHeader from "../../components/app/headers/LogoHeader";
import BottomNavbar from "../../components/app/BottomNavbar";
import disableScroll from "disable-scroll";
import { iosSetBackgroundColor, isNativeIOS } from "../../tools/ios";
import termsEnMd from "../../assets/markdowns/juisci-terms-en.md";
import termsEsMd from "../../assets/markdowns/juisci-terms-es.md";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import { displayToast } from "../../components/app/AppToast";
import ContentMarkdown from "../../components/ContentMarkdown";
import { loadMarkdown } from "../../domains/app/utils/loadMarkdown";

interface LocationState {
  hideTitle?: boolean;
  isPush?: boolean;
}

export default function TermsPage() {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { appLang, t } = useAppLang();
  const [markdownContent, setMarkdownContent] = useState<string | null>(null);
  const query = new URLSearchParams(location.search);
  const hideTitle = location.state?.hideTitle;
  const isPush = location.state?.isPush || !!query.get("isPush");

  useEffect(() => {
    (async function () {
      try {
        disableScroll.off();
        scrollTo(0, 0);
        if (isNativeIOS) iosSetBackgroundColor("#FFFFFF");

        const langMarkdowns: { [key in SupportedLanguagesEnum]?: unknown } = {
          [SupportedLanguagesEnum.EN]: termsEnMd,
          [SupportedLanguagesEnum.ES]: termsEsMd,
        };
        const md = langMarkdowns[appLang] ?? termsEnMd;

        setMarkdownContent(await loadMarkdown(md));
      } catch (error) {
        console.error("Couldn't load file.", error);
        displayToast(t("error:default"));
      }
    })();
  }, []);

  return (
    <>
      {isPush ? (
        <HeaderBoxAutoSafe>
          <HeaderLinks><NavBack /></HeaderLinks>
          <HeaderTitle>{hideTitle ? "" : t("Edit settings")}</HeaderTitle>
          <HeaderLinks />
        </HeaderBoxAutoSafe>
      ) : <LogoHeader />}

      <div style={{ padding: 30 }}>
        {!markdownContent ? (
          <Loader loaderOnly />
        ) : (
          <>
            <PolicyTextStyle>
              <ContentMarkdown>{markdownContent}</ContentMarkdown>
            </PolicyTextStyle>

            <hr style={{ margin: "30px 0" }} />

            <CustomButton
              onClick={() =>
                history.replace({
                  pathname: "/policy",
                  state: { isPush: true },
                })
              }
            >
              {t("See our Privacy Policy")}
            </CustomButton>
          </>
        )}
      </div>

      {!isPush && <BottomNavbar />}
    </>
  );
}
