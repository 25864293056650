import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppLang } from "../../../domains/app/hooks/useAppLang";
import { SpecialtyModel, TagModel } from "../../../domains/app/app.types";
import { useLazyGetArticlesListQuery } from "../../../domains/article/endpoints/getArticlesList";
import { getItemTranslation } from "../../../domains/app/utils/getItemTranslation";
import disableScroll from "disable-scroll";
import { gaEventViewItemListArticle } from "../../../tools/analytics/articleAnalytics";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import { displayToast } from "../../../components/app/AppToast";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavBack, NavLang } from "../../../components/app/headers/layout";
import BottomNavbar from "../../../components/app/BottomNavbar";
import FiltersDrawer from "../../../components/filters/FiltersDrawer";
import FadeIn from "react-fade-in/lib/FadeIn";
import SkeletonCard from "../../../components/skeleton/SkeletonCard";
import NoContent from "../../../components/NoContent";
import InfiniteScroll from "react-infinite-scroll-component";
import ItemList from "../../../components/app/ItemList";
import HomeContentFilters from "../../../components/home/layout/HomeContentFilters";
import ArticleCard from "../../../components/cards/ArticleCard";
import styled from "styled-components";

export default function HomeContentPublicationsPage() {
  const location = useLocation<{ specialty?: SpecialtyModel }>();
  const params = useParams<{ slug: string }>();
  const { appLang, t } = useAppLang();
  const [getArticlesList] = useLazyGetArticlesListQuery();
  const [isLoading, setLoading] = useState(true);
  const [articles, setArticles] = useState<any[]>([]);
  const [totalArticles, setTotalArticles] = useState(0);
  const [specialtyFilters, setSpecialtyFilters] = useState<any[] | null>(null);
  const [tagFilters, setTagFilters] = useState<any[] | null>(null);
  const [isFilterDrawerVisible, setFilterDrawerVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    disableScroll.off();
    loadFilters();
  }, []);

  useEffect(() => {
    if (specialtyFilters !== null && tagFilters !== null) {
      fetchArticles(true);
    }
  }, [specialtyFilters, tagFilters]);

  useEffect(() => {
    scrollTo(0, 0);
    fetchArticles(true);
  }, [appLang]);

  useEffect(() => {
    gaEventViewItemListArticle({
      articles,
      listName: AnalyticsItemList.HOME,
    });
  }, [articles]);

  async function loadFilters() {
    try {
      // Load cached filters (no specialty)
      if (!params.slug && localStorage.getItem("homePublicationsFilters")) {
        const { medical_specialties, tags } = JSON.parse(
          localStorage.getItem("homePublicationsFilters") as string
        );
        if (medical_specialties.length > 0 || tags.length > 0) {
          setSpecialtyFilters(medical_specialties);
          setTagFilters(tags);
        } else {
          localStorage.removeItem("homePublicationsFilters");
        }
        return;
      }

      // Load cached filters (specialty)
      if (params.slug && localStorage.getItem("homeSpecialtyFilters-" + params.slug)) {
        const { medical_specialties, tags } = JSON.parse(
          localStorage.getItem("homeSpecialtyFilters-" + params.slug) as string
        );
        if (medical_specialties.length > 0 || tags.length > 0) {
          setSpecialtyFilters(medical_specialties);
          setTagFilters(tags);
        } else {
          localStorage.removeItem("homeSpecialtyFilters-" + params.slug);
        }
        return;
      }

      // Set default filters
      setSpecialtyFilters(params.slug ? [{ uid: params.slug }] : []);
      setTagFilters([]);
    } catch (error) {
      console.error("Couldn't load filters.", error);
    }
  }

  async function fetchArticles(resetList = false) {
    try {
      const fetchParams: any = {
        limit: 20,
        offset: resetList ? 0 : articles.length,
        language: appLang,
      };

      if (specialtyFilters?.length) {
        fetchParams.medicalSpecialties = specialtyFilters.map((spe: SpecialtyModel) => spe.uid).join(",");
      }

      if (tagFilters?.length) {
        fetchParams.tags = tagFilters.map((tag: TagModel) => tag.uid).join(",");
      }

      setLoading(true);
      await getArticlesList(fetchParams)
        .unwrap()
        .then(({ docs, total }) => {
          const newList = resetList ? docs : [...articles, ...docs];
          setArticles(newList);
          setTotalArticles(total);
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error("Couldn't fetch articles.", error);
      displayToast(t("error:default"));
    }
  }

  function handleChangeFilters(filters: { medical_specialties: []; tags: [] }) {
    const { medical_specialties, tags } = filters;

    if (!medical_specialties.length && !tags.length) {
      localStorage.removeItem(!!params.slug
        ? "homeSpecialtyFilters-" + params.slug
        : "homePublicationsFilters"
      );
    } else {
      localStorage.setItem(!!params.slug
        ? "homeSpecialtyFilters-" + params.slug
        : "homePublicationsFilters",
        JSON.stringify({
          medical_specialties: Array.isArray(medical_specialties)
            ? medical_specialties
            : [{ uid: medical_specialties }],
          tags,
        })
      );
    }

    setSpecialtyFilters(Array.isArray(medical_specialties)
      ? medical_specialties
      : [{ uid: medical_specialties }]);
    setTagFilters(tags);
  }

  return (
    <>
      <Wrapper>
        <HeaderBoxAutoSafe>
          <HeaderLinks><NavBack /></HeaderLinks>
          <HeaderTitle>
            {location.state?.specialty ? getItemTranslation(location.state?.specialty) : t("Publications")}
          </HeaderTitle>
          <HeaderLinks><NavLang /></HeaderLinks>
        </HeaderBoxAutoSafe>

        <HomeContentFilters
          active={params.slug
            ? tagFilters ? tagFilters.length > 0 : false
            : specialtyFilters ? specialtyFilters.length > 0 : false
          }
          onClick={() => setFilterDrawerVisible(true)}
        />

        <div style={{ flex: 1 }}>
          {isLoading ? (
            <FadeIn key="loading">
              {[...Array(10)].map((_, index) => (
                <SkeletonCard key={`${index}--skeleton-card`} />
              ))}
            </FadeIn>
          ) : !articles.length ? (
            <NoContent
              specialty={params.slug
                ? getItemTranslation(location.state.specialty as SpecialtyModel)
                : t("your search")
              }
            />
          ) : (
            <FadeIn key="content">
              <InfiniteScroll
                dataLength={articles.length}
                next={fetchArticles}
                hasMore={articles.length < totalArticles}
                loader={null}
              >
                <ItemList>
                  {articles.map((article) => (
                    <ArticleCard
                      variant="white"
                      key={article.id}
                      article={article}
                      analyticsListName={AnalyticsItemList.HOME}
                    />
                  ))}
                </ItemList>
              </InfiniteScroll>
            </FadeIn>
          )}
        </div>

        <BottomNavbar />
      </Wrapper>

      <FiltersDrawer
        showDrawer={isFilterDrawerVisible}
        onClose={() => setFilterDrawerVisible(false)}
        medical_specialties={specialtyFilters ?? []}
        tags={tagFilters}
        slug={params.slug}
        handleFilters={(filters: any) => {
          handleChangeFilters(filters);
          setFilterDrawerVisible(false);
        }}
        title={
          params.slug
            ? getItemTranslation(location.state.specialty as SpecialtyModel)
            : t("Publications")
        }
      />
    </>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  background: var(--BRAND-CORE-Juisci-Yellow, #FFC408);
`;
