import * as React from "react";
import { useAppLang } from "../domains/app/hooks/useAppLang";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { frFR, enUS } from "@mui/x-date-pickers/locales";
import moment from "moment";
import i18n from "../config/i18n";
import { SupportedLanguagesEnum } from "../interfaces";

const theme = createTheme({
  palette: {
    primary: {
      light: "#FFF3CE",
      main: "#ffc408",
      dark: "#FFA100",
      contrastText: "#fff",
    },
    primary: {
      light: "#FFF3CE",
      main: "#ffc408",
      dark: "#FFA100",
      contrastText: "#fff",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.MuiTextField-root": {
            backgroundColor: "#fff",
            color: "#212121",
            fontFamily: "Inter",
            width: "100%",
            borderRadius: "4px",

            "& .MuiInputBase-input": {
              fontFamily: "Inter",
              fontWeight: "500",
              transform: "translateY(5px)",
            },

            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#fff",
              fontFamily: "Inter",
            },

            "& .MuiFormLabel-root": {
              fontFamily: "Inter",
              fontWeight: "500",
              color: "#81939c",
              "&.Mui-focused, &.MuiInputLabel-shrink": {
                transform: "scale(0.7) translate(20px, 7px)",
              },
            },
          },
        },
      },
    },
  },
});

export default function CustomDatePicker(props) {
  const { t } = useAppLang();
  const today = new Date();
  const maxDate = new Date();
  maxDate.setFullYear(today.getFullYear() - 13);
  const minDate = new Date();
  minDate.setFullYear(today.getFullYear() - 150);

  const [selectedDate, handleDateChange] = React.useState(
    !!props.defaultValue ? new Date(props.defaultValue) : null
  );

  moment.locale(i18n.resolvedLanguage);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        localeText={
          (i18n.resolvedLanguage === SupportedLanguagesEnum.FR ? frFR : enUS)
            .components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <DatePicker
          minDate={moment(minDate)}
          maxDate={moment(maxDate)}
          value={selectedDate ? moment(selectedDate) : null}
          format={
            i18n.resolvedLanguage === SupportedLanguagesEnum.FR
              ? "DD/MM/YYYY"
              : "MM/DD/YYYY"
          }
          label={`${t("common:form.field.birthdate")} (${t("common:optional")})`}
          onChange={(date) => {
            handleDateChange(moment(date));
            props.onChange(date);
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
