import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { SupportedLanguagesEnum } from "../../../interfaces";
import { UserRoomModel } from "../../user/user.types";
import { DEFAULT_LANGUAGE, LOGIN_SIGNUP_LANGUAGES, PUBLIC_INAPP_LANGUAGES } from "../../../config/i18n";
import { useTranslation } from "react-i18next";
import { setCurrentRoomLang, setPublicAppLang } from "../../lang/lang.reducer";
import { DebugLogger } from "../utils/debugLogger";

const debug = new DebugLogger();
debug.mute();

// NOTE: To be used outside of hook.
export function getAppLang() {
  return localStorage.getItem("appLang") as SupportedLanguagesEnum ?? DEFAULT_LANGUAGE;
}

function determinePublicAppLang() {
  const storedLang = localStorage.getItem("appLang") as SupportedLanguagesEnum ?? null;
  if (storedLang && PUBLIC_INAPP_LANGUAGES.includes(storedLang)) {
    return storedLang;
  }

  const navigatorLang = navigator.language.slice(0, 2) as SupportedLanguagesEnum;
  if (navigatorLang && PUBLIC_INAPP_LANGUAGES.includes(navigatorLang)) {
    return navigatorLang;
  }

  return DEFAULT_LANGUAGE;
}

function determineRoomLang(room: UserRoomModel) {
  const navigatorLang = navigator.language.slice(0, 2) as SupportedLanguagesEnum;
  if (navigatorLang && room.allowedLanguages.includes(navigatorLang)) {
    debug.log("User locale is accepted in this room and set as the language", { navigatorLang });
    return navigatorLang;
  }
  debug.log("User locale not accepted in this room, use default", { defaultRoomLang: room.allowedLanguages[0] });
  return room.allowedLanguages[0];
}

export function getStoredRoomLang(roomId: string): SupportedLanguagesEnum | null {
  const roomLangs = JSON.parse(localStorage.getItem("roomLangs") ?? "{}") ?? {};
  // debug.log("Get stored room lang", { roomId, roomLang: roomLangs[roomId] ?? null });
  return roomLangs[roomId] ?? null;
}

function storeRoomLang(roomId: string, lang: SupportedLanguagesEnum) {
  debug.log("Storing room lang", { lang });
  const roomLangs = JSON.parse(localStorage.getItem("roomLangs") ?? "{}") ?? {};
  roomLangs[roomId] = lang;
  localStorage.setItem("roomLangs", JSON.stringify(roomLangs));
}

export function useAppLang() {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const publicAppLang = useAppSelector((state) => state.lang.publicAppLang);
  const currentRoomLang = useAppSelector((state) => state.lang.currentRoomLang);
  const currentRoomId = useAppSelector((state) => state.user.currentRoomId);
  const roomData: UserRoomModel = useAppSelector((state) => state.user.cache.currentRoom.data);
  const roomOrganisationUid = useAppSelector((state) => state.user.cache.currentRoom.data?.organisation?.uid ?? null);
  const roomAllowedLanguages = useAppSelector((state) => state.user.cache.currentRoom.data?.allowedLanguages ?? [DEFAULT_LANGUAGE]);
  const isInRoom = !!roomOrganisationUid || location.pathname.includes("/room");
  const isOnLoginSignup = ["/login", "/signup", "/terms", "/policy"].some((pathRoot: string) => location.pathname.startsWith(pathRoot));
  const appLang = isInRoom
    ? currentRoomLang as SupportedLanguagesEnum
    : publicAppLang as SupportedLanguagesEnum ?? navigator.language.slice(0, 2) as SupportedLanguagesEnum;

  useEffect(() => {
    if (!isOnLoginSignup) {
      if (!isInRoom && !isOnLoginSignup) {
        if (!publicAppLang === null || !PUBLIC_INAPP_LANGUAGES.includes(publicAppLang)) {
          const lang = determinePublicAppLang();
          debug.log("Set public app lang when null or not allowed", { currentRoomId, publicAppLang, lang });
          setAppLang(lang);
        }
      } else if (!!currentRoomId) { // Wait until room is loaded
        const storedRoomLang = getStoredRoomLang(roomData._id);
        if (storedRoomLang === null) {
          setAppLang(determineRoomLang(roomData));
        }
        if (storedRoomLang && currentRoomLang !== storedRoomLang) {
          debug.log("Set room lang when lang is different", { currentRoomId, currentRoomLang, storedRoomLang });
          setAppLang(storedRoomLang);
        }
      }

      i18n.changeLanguage(appLang);
    }
  }, [currentRoomId]);

  function setAppLang(lang: SupportedLanguagesEnum) {
    debug.log("Set app lang", { lang, isInRoom });
    if (isInRoom) {
      if (!roomAllowedLanguages.includes(lang)) {
        debug.log("Lang not allowed in room", { lang, roomAllowedLanguages });
        lang = DEFAULT_LANGUAGE;
      }
      dispatch(setCurrentRoomLang(lang));
      storeRoomLang(currentRoomId, lang);
    } else {
      const allowedLanguages = isOnLoginSignup
        ? LOGIN_SIGNUP_LANGUAGES
        : PUBLIC_INAPP_LANGUAGES;
      if (!allowedLanguages.includes(lang)) {
        lang = DEFAULT_LANGUAGE;
      }
      dispatch(setPublicAppLang(lang));
      localStorage.setItem("appLang", lang);
    }
    i18n.changeLanguage(lang);
  }

  function t(key: string, options: any = {}) {
    // Use browser/device language when signing in/signing up
    // if (isUsingDeviceLang) {
    //   return i18n.t(key, { lng: navigator.language }) as string;
    // }

    // NOTE: This hardcoded implementation is temporary.
    const isUsingFormalFrench = roomOrganisationUid?.toLowerCase().includes("takeda");
    if (isUsingFormalFrench && appLang === SupportedLanguagesEnum.FR) {
      return i18n.t(key, { lng: "yo" }) as string;
    }

    return i18n.t(key, options) as string;
  }

  return {
    appLang,
    setAppLang,
    t,
  };
}
