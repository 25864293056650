import { useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAppSelector } from "../../../redux";
import { useAppLang } from "../../../domains/app/hooks/useAppLang";
import { useLazyGetVideoStoriesListQuery } from "../../../domains/video/endpoints/getVideoStoriesList";
import { VideoModel } from "../../../domains/video/video.types";
import VideoCard from "../../VideoCard";
import styled from "styled-components";
import { ReactComponent as VideosIcon } from "../../../assets/icons/home_videos_icon.svg";
import SkeletonContentCard from "../../profile/SkeletonContentCard";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import { gaEventViewItemListVideo } from "../../../tools/analytics/videoAnalytics";

export default function HomeVideoStories() {
  const history = useHistory();
  const videoStories = useAppSelector(
    (state) => state.videos.cache.videoStories
  );
  const { appLang, t } = useAppLang();
  const mountRef = useRef(false);
  const [isMounted, setMounted] = [
    !!mountRef.current,
    () => (mountRef.current = true),
  ];
  const [getVideoStoriesList] = useLazyGetVideoStoriesListQuery();

  useEffect(() => {
    if (!isMounted) {
      if (!videoStories.length) refreshVideoStories();
      setMounted();
    }
  }, []);

  useEffect(() => {
    if (isMounted) refreshVideoStories();
  }, [appLang]);

  async function refreshVideoStories() {
    await getVideoStoriesList({
      offset: 0,
      limit: 20,
      language: appLang,
    })
      .unwrap()
      .then(({ docs }) => {
        gaEventViewItemListVideo({
          videos: docs,
          listName: AnalyticsItemList.DISCOVERY_VIDEOS,
        });
      })
      .catch((error) => {
        console.error("Couldn't get video stories.", error);
      });
  }

  return (
    <div className='section-content padding'>
      <Header>
        <div className='title'>
          <VideosIcon />
          <h3>{t("Videos")}</h3>
        </div>
        <Link to='/discovery/videos'>
          <span>{t("common:action.seeAll")}</span>
        </Link>
      </Header>

      <HScrollContainer className='scrollbar-hidden'>
        {videoStories
          ? videoStories.slice(0, 10)?.map((story: VideoModel) => (
              <VideoCard
                key={story._id + "--story-homevideocard"}
                variant='story'
                video={story}
                onClick={() =>
                  history.push({
                    pathname: "/video/story/" + story.slug,
                    state: {
                      stories: videoStories,
                      story,
                    },
                  })
                }
              />
            ))
          : [
              <div
                style={{
                  width: "50%",
                  height: 200,
                }}
              >
                <SkeletonContentCard
                  key='skeleton-video-story-1'
                  contentFormat='video'
                />
              </div>,
              <div
                style={{
                  width: "50%",
                  height: 200,
                }}
              >
                <SkeletonContentCard
                  key='skeleton-video-story-2'
                  contentFormat='video'
                />
              </div>,
            ]}
      </HScrollContainer>
    </div>
  );
}

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 21px 16px;
  .title {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.00011em;
    color: #313b42;
    margin: 0;
  }
  span {
    color: #ce0868;
  }
  a {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #ce0868;
    margin: 0;
  }
`;

const HScrollContainer = styled.div`
  overflow-x: auto;
  padding: 0 24px 24px;
  display: flex;
  align-items: stretch;
  gap: 8px;
`;
