import i18n, { DEFAULT_LANGUAGE } from "../../config/i18n";
import { getCurrentUser } from "../../domains/user/endpoints/getCurrentUser";
import { OrganisationUid } from "../../interfaces";
import { store } from "../../redux";
import { getFirebaseToken } from "../../services/firebase";
import { gaPushEvent, gaPushEventDelay } from "./gaPushEvent";

enum Event {
  PAGE_VIEW = "page_view",
  SELECT_ITEM = "select_item",
  VIEW_ITEM = "view_item",
  VIEW_ITEM_LIST = "view_item_list",
}

export async function gaEventPageView() {
  let user = null;
  const firebaseId = await getFirebaseToken();
  if (firebaseId) user = await getCurrentUser();

  const roomOrganisationUid = store.getState().user.cache.currentRoom.data?.organisation?.uid ?? null;

  const title = window.location.pathname.startsWith("/room/audio")
    ? "audio room"
    : window.location.pathname.replace("/", "");

  gaPushEvent(
    Event.PAGE_VIEW,
    {
      environment: {
        name: window.location.hostname.includes("app.juisci") ? "prod" : "dev",
        entity: "web app",
        partner: roomOrganisationUid ?? OrganisationUid.JUISCI,
      },
      page: {
        title,
        language: i18n.resolvedLanguage || DEFAULT_LANGUAGE,
      },
      user: {
        account_id: user?.uid || null,
        profile: user?.profession?.uid || null,
        specialty: user?.main_specialty?.uid || null,
        interests:
          user?.medical_specialties
            ?.map((specialty: { uid: string }) => specialty.uid)
            ?.toString() || null,
        login_status: !!firebaseId ? "logged in" : "logged out",
      },
    }
  );
}

export function gaEventSelectItem(item: unknown) { // TODO: Improve typing.
  gaPushEvent(Event.SELECT_ITEM, { ecommerce: { items: [item] } });
}

export function gaEventViewItem(item: unknown) { // TODO: Improve typing.
  gaPushEventDelay(Event.VIEW_ITEM, { ecommerce: { items: [item] } });
}

export function gaEventViewItemList(items: unknown[]) { // TODO: Improve typing.
  gaPushEventDelay(Event.VIEW_ITEM_LIST, { ecommerce: { items } });
}
