import { OnboardingHeading, OnboardingHero, OnboardingSuccessWrapper } from "../../components/onboarding/layout";
import { useAppLang } from "../../domains/app/hooks/useAppLang";
import Lottie from "lottie-react";
import JuisciFillTube from "../../assets/animations/tube-filling.json";
import { Trans } from "react-i18next";

export default function OnboardingSuccessFinal() {
  const { t } = useAppLang();

  return (
    <OnboardingSuccessWrapper>
      <OnboardingHero>
        <OnboardingHeading>
          <h1>{t("signup:onboarding.success.title")}</h1>
          <span className="subtitle">{t("signup:onboarding.success.subtitle")}</span>
        </OnboardingHeading>
      </OnboardingHero>

      <Lottie
        className="lottie-animation"
        animationData={JuisciFillTube}
        loop={true}
        autoplay={true}
      />

      <h2>
        <Trans
          i18nKey="signup:onboarding.success.info1"
          components={{ highlight: <span className="highlight" /> }}
        />
      </h2>
      <p>
        <Trans
          i18nKey="signup:onboarding.success.info2"
          components={{ b: <b /> }}
        />
      </p>
    </OnboardingSuccessWrapper>
  );
}
