import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ArticleModel } from "../../domains/article/article.types";
import { PlaylistModel } from "../../domains/playlist/playlist.types";
import { UserModel, UserRoomModel } from "../../domains/user/user.types";
import { getCurrentOS } from "../../tools/utils";
import { patchUser } from "../../domains/user/endpoints/patchUser";
import { userApi } from "./user.service";
import i18n from "../../config/i18n";
import { SupportedLanguagesEnum } from "../../interfaces";
import { getCurrentUser } from "../../domains/user/endpoints/getCurrentUser2";
import { getAcceptedConnectionRequests } from "../../domains/user/endpoints/getAcceptedConnectionRequests";
import { getPendingConnectionRequests } from "../../domains/user/endpoints/getPendingConnectionRequests";
import { getUserRooms } from "../../domains/user/endpoints/getUserRooms";
import { filterArrayDuplicates } from "../../domains/app/utils/filterArrayDuplicates";

export interface UserState {
  cache: {
    currentRoom: {
      data: UserRoomModel | null;
    };
    audioRoom: {
      playlists: PlaylistModel[];
    };
  };
  user: UserModel | Boolean;
  saves: string[] | Boolean;
  playlists: PlaylistModel[] | Boolean;
  rooms: UserRoomModel[];
  currentRoomId: string | null;
  connections: {
    accepted: [];
    pending: [];
  };
  readAnnouncements: string[];
  isUninitialized: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const initialState: UserState = {
  cache: {
    currentRoom: {
      data: null,
    },
    audioRoom: {
      playlists: [],
    },
  },
  user: false,
  saves: false,
  playlists: false,
  rooms: [],
  currentRoomId: null,
  connections: {
    accepted: [],
    pending: [],
  },
  readAnnouncements: [],
  isUninitialized: true,
  isLoading: false,
  isFetching: false,
  isError: false,
  isSuccess: false,
};

export const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserModel>) => {
      state.user = action.payload;
    },
    setSaves: (state, action: PayloadAction<string[]>) => {
      state.saves = action.payload;
    },
    setPlaylists: (state, action: PayloadAction<PlaylistModel[]>) => {
      state.playlists = action.payload;
    },
    enterRoom: (state, action: PayloadAction<UserRoomModel>) => {
      state.currentRoomId = action.payload._id;
      state.cache.currentRoom.data = action.payload;
    },
    exitRoom: (state) => {
      state.currentRoomId = null;
      state.cache.currentRoom.data = null;
      state.cache.audioRoom.playlists = [];
    },
    memorizeAudioRoomPlaylists: (state, action: PayloadAction<PlaylistModel[]>) => {
      state.cache.audioRoom.playlists = action.payload;
    },
    flagAnnouncementAsRead: (state, action: PayloadAction<string>) => {
      if (!state.readAnnouncements.includes(action.payload)) {
        state.readAnnouncements.push(action.payload);
      }
    },
  },
  extraReducers: async (builder) => {
    // TODO: Remove (deprecated)
    builder.addMatcher(
      userApi.endpoints.getUser.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
        state.isUninitialized = false;
        state.isLoading = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.isError = false;

        // Detect if user language is different from app
        // if (payload.language !== i18n.resolvedLanguage)
        //   setTimeout(() => i18n.changeLanguage(payload.language), 100);

        // Detect if user has 'device' attribute empty
        if (!payload?.device?.length) patchUser({ device: getCurrentOS() });

        if (typeof payload?.language === "string") {
          if (Object.keys(SupportedLanguagesEnum).includes(payload.language)) {
            patchUser({
              language:
                SupportedLanguagesEnum[
                payload.language as unknown as keyof typeof SupportedLanguagesEnum
                ],
            });
          }
        } else {
          patchUser({
            language: SupportedLanguagesEnum.EN,
          });
        }
      }
    );
    // TODO: Remove (deprecated)
    builder.addMatcher(userApi.endpoints.getUser.matchPending, (state) => {
      state.isLoading = state.isUninitialized;
      state.isFetching = true;
    });
    // TODO: Remove (deprecated)
    builder.addMatcher(userApi.endpoints.getUser.matchRejected, (state) => {
      state.isLoading = false;
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
    });

    builder.addMatcher(
      getCurrentUser.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;

        // TODO: Rework this part with the backend. Some request seem to be unnecessary.

        // Detect if user language is different from app
        // if (payload.language !== i18n.resolvedLanguage) {
        //   setTimeout(() => i18n.changeLanguage(payload.language), 100);
        // }

        // Detect if user has 'device' attribute empty
        if (!payload?.device?.length) patchUser({ device: getCurrentOS() });

        if (typeof payload?.language === "string") {
          if (Object.keys(SupportedLanguagesEnum).includes(payload.language)) {
            patchUser({
              language:
                SupportedLanguagesEnum[
                payload.language as unknown as keyof typeof SupportedLanguagesEnum
                ],
            });
          }
        } else {
          patchUser({
            language: SupportedLanguagesEnum.EN,
          });
        }
      }
    );

    builder.addMatcher(getCurrentUser.matchRejected, (_state, error) => {
      console.error("Couldn't get current user data.", error);
      throw error;
    });

    builder.addMatcher(
      userApi.endpoints.getPlaylists.matchFulfilled,
      (state, { payload }) => {
        state.playlists = payload.docs;

        const saves = Array.from(
          new Set(
            payload.docs.flatMap((playlist: PlaylistModel) => [
              ...playlist.playlist.map((article: ArticleModel) => article._id),
              ...playlist?.playlistContent,
            ])
          )
        );

        state.saves = saves;
      }
    );

    builder.addMatcher(getAcceptedConnectionRequests.matchFulfilled,
      (state, { payload }) => {
        state.connections.accepted = payload;
      }
    );
    builder.addMatcher(getPendingConnectionRequests.matchFulfilled,
      (state, { payload }) => {
        state.connections.pending = payload;
      }
    );
    builder.addMatcher(getUserRooms.matchFulfilled,
      (state, { payload }) => {
        // state.rooms = payload;
        // NOTE: Fix for duplicate rooms sent by the backend.
        state.rooms = filterArrayDuplicates(payload);
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  setUser,
  setSaves,
  setPlaylists,
  enterRoom,
  exitRoom,
  memorizeAudioRoomPlaylists,
  flagAnnouncementAsRead,
} = slice.actions;

export default slice.reducer;
