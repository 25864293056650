// NOTE: The window.dataLayer is a JavaScript object used in Google Tag Manager (GTM)
// to store and manage data. It is a virtual layer that sits between a website
// or application and the data processing tools, such as Google Analytics.

export function gaPushEvent(event: string, params?: any) {
  if (process.env.NODE_ENV === "development") {
    console.log("Analytics Event:", event, params);
  }

  const dataLayer = (window as unknown as { dataLayer: any }).dataLayer;

  if (params && Object.keys(params).includes("ecommerce")) {
    // NOTE: Safety for unexpected edge cases.
    dataLayer.push({ ecommerce: null });
  }

  let payload = { event };
  if (params) payload = { event, ...params };

  dataLayer.push(payload);
}

export function gaPushEventDelay(event: string, params?: any) {
  setTimeout(() => {
    gaPushEvent(event, params);
  }, 1000);
}
